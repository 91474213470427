import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App/App';
import registerServiceWorker from './registerServiceWorker';
import '../src/css/Content/Site.css'; 
import '../src/css/Content/Mybootstrap.css';
import '../src/css/Content/styles.min.css'; 
import '../src/css/utility.min.css'; 


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement); 
registerServiceWorker();

