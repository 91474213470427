import React, { Component } from 'react';
import leftbottomcorner from '../images/leftbottomcorner.png';
import logo from '../images/logo.png';
import cartton from '../images/cartton.png';
import Lefttop_Corner from '../images/lefttopcorner.png';
import bottomrightcorner from '../images/bottomrightcorner.png';
import admission from '../images/SVG/admission.svg';
import emr from '../images/SVG/emr.svg';
import docs from '../images/SVG/docs.svg';
import ourmission from '../images/SVG/ourmission.svg';
import practice from '../images/SVG/practice.svg';
import overview from '../images/SVG/overview.svg';
import { FormatQuote } from '@material-ui/icons';
import 'aos/dist/aos.css';
import AOS from 'aos';
 

export class HomePage extends Component {
    componentWillMount() {
        AOS.init({
            once: true,
            delay: 100
        });
    }
    render() {
        return ( 
            <div id="Overall" className="overallimg-div Homepage">
                <section className="try_now">
                    <div className="lhs_contside">
                        <div id="lefttopcorner" className="leftcorner">
                            <img src={leftbottomcorner} className="compImg" alt="Navbar Logo" />
                        </div>
                        <div id="logoContent" className="pull-left logo-content">
                            <img src={logo} className="compImg" alt="Company Logo" />
                            <div className="pull-left w-100 app-content">
                                <p style={{ 'margin': '0px' }}>A global leader in next-generation digital</p>
                                <p style={{ 'margin': '0px' }}>services and consulting. we bring you powerful</p>
                                <p style={{ 'margin': '0px' }}>advantages to navigate your digital transformation</p>
                            </div>
                            <div className="Try-button">
                                <a href="Signup">TRY IT NOW</a>
                            </div>
                        </div>
                        <div id="leftbottomcorner" className="pull-left" style={{ 'position': 'absolute', 'bottom': '0px', 'left': '-33px', 'display': 'none' }}>
                            <img src={Lefttop_Corner} alt="Left bottom logo" style={{ 'height': '146px' }} />
                        </div>
                    </div>
                    <div className="rhs_illuside">
                        <div id="rightsidelapImage" className="pull-right rhsimg-div">
                            <img src={cartton} loading="lazy" className="cartoon-img" alt="Illustration Thumbnail" />
                        </div>
                        <div id="bottomrightcorner" className="pull-left" style={{ 'position': 'absolute', 'bottom': '0px', 'right': '0px', 'display': 'none' }}>
                            <img src={bottomrightcorner} alt="Right bottom logo" style={{ 'height': '146px' }} />
                        </div>
                    </div>
                </section>

                <section className="fcols">
                    <h1>Our Healthcare Services</h1>
                    <div className="flex-wrap flex-center-x">
                        <article>
                            <div>
                                <img src={admission} alt="Admission Image" alt="ADT Thumbnail" />
                                <header>ADT</header>
                                <p>
                                    Patients transition from one provider or healthcare setting to another as the
                                    patient's health care needs require. These transitions trigger Electronic Health Records to
                                    generate Admission Discharge Transfer (ADT) notifications that identify the patient along with
                                    important details that provide insight to an extremely complex set of care decisions being made by care
                                    teams, families and the patient.
                            </p>
                            </div>
                        </article>
                        <article>
                            <div>
                                <img src={practice} alt="Practice Image" alt="Practice Thumbnail" />
                                <header>PMS</header>
                                <p>
                                    Practice Management System (PMS) is a form of system found in medical offices that is designed to deal with day-to-day operations using desktop system ,
                                    client-server system and Internet-based system. Medical informatics such as this are often requested with little notice. While vendors for EMRs and PMS can be different, the IT world is seeing the two entities combine at an increasing rate.
                            </p>
                            </div>
                        </article>
                        <article>
                            <div>
                                <img src={emr} alt="EMR Image" alt="EMR Thumbnail" />
                                <header>EMR</header>
                                <p>
                                    EMR provide a ambulatory practices with recent technology and services on your
                                    finger tips with our user friendly mobile and tablet applications on Android and
                                    iOS platforms. Doctor can get better results for their clinical practices.
                                    We create an application that understands the flow of the ambulatory practices
                                    from appointments to SOAP notes generation.
                                    
                            </p>
                            </div>
                        </article>
                        <article>
                            <div>
                                <img src={docs} alt="Documents Image" alt="DMS Thumbnail" />
                                <header>DMS</header>
                                <p>
                                    The health care industry utilizes a lot of forms and paperwork. Patient forms, doctor
                                    notes, payment receipts, insurance cards, diagnosis codes, lab results all from just
                                    one patient! Well, you don't have to look any further when seeking a document
                                    management solution that will keep your organization's medical records safe, secure
                                    and organized.
                            </p>
                            </div>
                        </article>
                    </div>
                </section>
                <section className="omission">
                    <div className="lhs_mode">
                        <h1>OUR MISSION</h1>
                        <article data-aos="fade-right">
                            <p>
                                <FormatQuote style={{ 'transform':'scaleX(-1)' }} />Together with our customers and partners, we are creating a sustainable future for health care. Together we are charting a course to better health <FormatQuote />
                            </p>
                        </article>
                    </div>
                    <div className="rhs_mode">
                        <img src={ourmission} alt="Mission Image" alt="Mission Thumbnail" />
                    </div>
                </section>
                <section className="omission overview">
                    <div className="lhs">
                        <img src={overview} alt="Overview Image" alt="Overview Thumbnail" />
                    </div>
                    <div className="rhs">
                        <h1>Overview</h1>
                        <article data-aos="zoom-in">
                            <p>
                                Hospital Information system is a hospital software designed for small and mid-size hospitals and clinics, individual doctors, physicians as well as Multi-Specialty Hospitals. The overriding objective of the Hospital Information system is to reduce the time of Doctors in Managing the Various patients such as In Patients / Outpatients and Emergency. This is to be achieved by leveraging technology to harmonize procedures across the departments of Hospital Information, and beyond to geographical level, and to allow providers to track and manage the patient electronically minimizing the need for visitingthe patient location.
                                All the services are electronically linked and managed remotely by designing a Web Interface.
                        </p>
                        </article>
                    </div>
                </section>
            </div>
        ); 
    }
}
